import React, { memo } from 'react'
import { Box, Container, Typography, styled } from '@mui/material'

// @ts-ignore
import { DatoCmsButton } from '__generated__/gatsby-types'

import BackgroundPlx from 'components/atoms/BackgroundPlx'
import FixedHeightHeroContainer from 'components/atoms/FixedHeightHeroContainer'
import Sun from 'components/atoms/Sun'
import CurveMask from 'components/atoms/CurveMask'
import MainHeading from 'components/molecules/MainHeading'

export interface TextHeroCurvePropsBase {
  keyId: string
  heading: string
  text?: string
  upliftAmount?: number
  maskColor?: string
}

// prettier-ignore
const StyledFixedHeightHeroContainer = styled(FixedHeightHeroContainer)(({ theme }) => ({
    background: theme.palette.secondary.main,
    position: 'relative',
}))
const StyledBackgroundPlx = styled(BackgroundPlx)(({ theme }) => ({
  background: theme.palette.secondary.main,
}))

const TextHeroCurve = ({
  keyId,
  heading,
  text,
  upliftAmount,
  maskColor,
}: TextHeroCurveProps) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <>
      <StyledFixedHeightHeroContainer
        sx={{
          pb: upliftAmount ? upliftAmount / 2 : 0,
          overflow: 'hidden',
        }}
      >
        <StyledBackgroundPlx parallaxData={parallaxData}>
          <Sun size="large" color="light" position="bottom-left-xl" />
          <Sun size="xl" color="light" position="top-right-xl" />
        </StyledBackgroundPlx>
        <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <CurveMask bgColor={maskColor ? maskColor : 'lightGrey'} overflow />
        </Box>
        <Container
          maxWidth="lg"
          className="fixed-height"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: 'white',
            position: 'relative',
          }}
        >
          <MainHeading
            text={heading}
            keyId={`heading-${keyId}`}
            breakpoint="xs"
            variant="h1"
            component="h1"
          />
          {text && (
            <Typography
              variant="leadParagraph"
              sx={{ color: 'white', fontWeight: 'regular', mt: 2 }}
            >
              {text}
            </Typography>
          )}
        </Container>
      </StyledFixedHeightHeroContainer>
    </>
  )
}

export default memo(TextHeroCurve)
