import React, { memo } from 'react'
import { Container, Grid } from '@mui/material'

import {
  DatoCmsEvent,
  DatoCmsNewsArticle,
  DatoCmsPatientStory,
  DatoCmsSupporter,
  DatoCmsDonorCampaign,
  DatoCmsSeoMetaTags,
  // @ts-ignore
} from '__generated__/gatsby-types'

import Card from 'components/molecules/Card'
import Pagination from 'components/molecules/Pagination'
import Layout from 'components/organisms/global/Layout'
import TextHero from 'components/organisms/hero/TextHero'
import TextHeroCurve from 'components/organisms/hero/TextHeroCurve'
import Sun from 'components/atoms/Sun'
import SunBoxContainer from 'components/atoms/SunBoxContainer'
import LandingItemsSummary from 'components/molecules/LandingItemsSummary'

export interface AutomatedLandingContextProps {
  numPages: number
  perPage: number
  currentPage: number
  totalItems: number
  summaryItemName: string
  pagePathPrefix: string
  landingPathPrefix: string
  cards: [
    | DatoCmsEvent
    | DatoCmsNewsArticle
    | DatoCmsPatientStory
    | DatoCmsSupporter
    | DatoCmsDonorCampaign
  ]
}

interface AutomatedLandingProps extends AutomatedLandingContextProps {
  heading: string
  heroText?: string
  keyId: string
  metaTags: DatoCmsSeoMetaTags
  curveLayout?: boolean
}

const AutomatedLanding = ({
  heading,
  heroText,
  keyId,
  metaTags,
  cards,
  currentPage,
  perPage,
  numPages,
  totalItems,
  summaryItemName,
  pagePathPrefix,
  landingPathPrefix,
  curveLayout,
}: AutomatedLandingProps) => {
  return (
    <Layout metadata={{ seoTags: metaTags }} forceSolidNav>
      {curveLayout && (
        <TextHeroCurve heading={heading} text={heroText} keyId={keyId} />
      )}
      <SunBoxContainer
        component="section"
        bgcolor="lightGrey"
        py={{ xs: 6, sm: 7, md: 8, lg: 9, xl: 10 }}
      >
        {!curveLayout && <Sun size="large" position="top-right" />}
        <Container maxWidth="xl">
          {!curveLayout && (
            <TextHero heading={heading} text={heroText} keyId={keyId} />
          )}

          {/* <LandingItemsSummary
            currentPage={currentPage}
            totalItems={totalItems}
            perPage={perPage}
            numPages={numPages}
            itemName={summaryItemName}
          /> */}
          <Grid container spacing={3} alignItems="stretch" sx={{ mt: 3 }}>
            {cards.map(
              (card: DatoCmsEvent | DatoCmsNewsArticle | DatoCmsSupporter) => {
                return (
                  <Grid
                    item
                    md={6}
                    xl={4}
                    key={card.node.originalId}
                    display="flex"
                    alignItems="stretch"
                  >
                    <Card
                      heading={card.node.cardContent[0].heading}
                      subHeading={card.node.cardContent[0].subHeading}
                      apiKey={card.node.model.apiKey}
                      text={card.node.cardContent[0].text}
                      buttonLabel={
                        card.node.buttonLabel
                          ? card.node.buttonLabel
                          : 'Read more'
                      }
                      image={card.node.heroImage.gatsbyImageData}
                      slug={card.node.slug}
                      date={
                        card.node.formattedDate
                          ? card.node.formattedDate
                          : undefined
                      }
                      dateLayout
                      textButton
                      socialMedia={card.node.socialMedia}
                      eventType={
                        card.node.eventType ? card.node.eventType : undefined
                      }
                      pathPrefix={pagePathPrefix}
                    />
                  </Grid>
                )
              }
            )}
          </Grid>
          <Pagination
            pathPrefix={landingPathPrefix}
            numPages={numPages}
            currentPage={currentPage}
          />
        </Container>
      </SunBoxContainer>
    </Layout>
  )
}

export default memo(AutomatedLanding)
