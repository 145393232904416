import React, { memo } from 'react'
import { Box, Divider, Typography } from '@mui/material'

import MainHeading from 'components/molecules/MainHeading'

interface TextHeroProps {
  keyId: string
  heading: string
  text?: string
}

const TextHero = ({ keyId, heading, text }: TextHeroProps) => {
  return (
    <Box pt={{ xs: 6, sm: 7, md: 8, lg: 9, xl: 10 }} textAlign="center">
      <MainHeading variant="h1" text={heading} keyId={keyId} breakpoint="xs" />
      {text && <Typography sx={{ mt: 2 }}>{text}</Typography>}
      <Divider sx={{ mt: 4, mb: 3 }} />
    </Box>
  )
}

export default memo(TextHero)
