import React, { memo } from 'react'
import { graphql } from 'gatsby'

// @ts-ignore
import { AutomatedLandingQuery } from '__generated__/gatsby-types'

import AutomatedLanding, {
  AutomatedLandingContextProps,
} from './layouts/AutomatedLanding'

interface Props {
  data: AutomatedLandingQuery
  pageContext: AutomatedLandingContextProps
}

const NewsEventsLanding = ({ data, pageContext }: Props) => {
  const d = data.datoCmsAutomatedLanding

  return (
    <AutomatedLanding
      keyId={d.originalId}
      heading={d.pageTitleNode.childMarkdownRemark.html}
      heroText={d.text}
      metaTags={d.seoMetaTags}
      {...pageContext}
      summaryItemName={d.summaryItemName}
    />
  )
}

export default memo(NewsEventsLanding)

export const pageQuery = graphql`
  query AutomatedLandingQuery($originalId: String!) {
    datoCmsAutomatedLanding(originalId: { eq: $originalId }) {
      originalId
      pageTitleNode {
        childMarkdownRemark {
          html
        }
      }
      text
      summaryItemName
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
