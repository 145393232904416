import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Button, IconButton, styled } from '@mui/material'
import { CaretLeft, CaretRight } from 'phosphor-react'

interface SharedProps {
  currentPage: number
  pathPrefix: string
}

interface PaginationProps extends SharedProps {
  numPages: number
}

interface CreatePaginationUrlProps {
  pathPrefix: string
  pageNumber: number | null
}

interface CreatePaginationItemsProps extends SharedProps {
  length: number
  increment?: number
}

// constcreatePaginationUrl = (pathPrefix, pageNum) => {
//   const prefix = `/${pathPrefix}`
//   let page = ''
//   if (pageNum && pageNum != 1 && pageNum > -1) {
//     page = `/${pageNum}`
//   }
//   return prefix + page
// }

const PageButton = styled(IconButton)(({ theme }) => ({
  width: '3.4rem',
  height: '3.4rem',
  fontSize: '1.6rem',
  margin: '0.3rem',
  ['&.is-current']: {
    backgroundColor: theme.palette.common.white,
  },
}))
const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  ['&:hover']: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  ['.MuiButton-startIcon, .MuiButton-endIcon']: {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const createPaginationUrl = ({
  pathPrefix,
  pageNumber,
}: CreatePaginationUrlProps) => {
  const prefix = `/${pathPrefix}`
  let page = ''
  if (pageNumber && pageNumber != 1 && pageNumber > -1) {
    page = `/${pageNumber}`
  }
  return prefix + page
}

const createPaginationItems = ({
  length,
  currentPage,
  pathPrefix,
  increment,
}: CreatePaginationItemsProps) => {
  const inc = increment ? increment : 1
  return Array.from({ length }, (_, i) => {
    return {
      link: createPaginationUrl({
        pathPrefix: pathPrefix,
        pageNumber: i + inc === 1 ? 1 : i + inc,
      }),
      index: i + inc,
      pageNum: i + inc === 1 ? '1' : i + inc,
      current: currentPage === i + inc,
    }
  })
}

const Pagination = ({ numPages, currentPage, pathPrefix }: PaginationProps) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages

  const prevPageNum = currentPage - 1 === 1 ? null : currentPage - 1
  const nextPageNum = currentPage + 1

  const prevPageLink = isFirst
    ? null
    : createPaginationUrl({ pathPrefix: pathPrefix, pageNumber: prevPageNum })

  const nextPageLink = isLast
    ? null
    : createPaginationUrl({ pathPrefix: pathPrefix, pageNumber: nextPageNum })

  const firstPageLink = pathPrefix
  const lastPageLink = createPaginationUrl({
    pathPrefix: pathPrefix,
    pageNumber: numPages,
  })

  let pagerItems = []
  let lastLink = null

  if (numPages <= 3) {
    pagerItems = [
      ...pagerItems,
      ...createPaginationItems({
        length: numPages,
        currentPage: currentPage,
        pathPrefix: pathPrefix,
      }),
    ]
  } else {
    // We have a situation where we have to show the first
    // item, three items around the current one
    // and also the last item
    if (currentPage <= 3) {
      // If the current one is closer to the start
      pagerItems = [
        ...pagerItems,
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          pathPrefix: pathPrefix,
        }),
        {
          separator: true,
          index: 'starter-separator',
        },
      ]
      lastLink = true
    } else if (currentPage > numPages - 3) {
      pagerItems = [
        ...pagerItems,
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          pathPrefix: pathPrefix,
          increment: numPages - 2,
        }),
      ]
    } else {
      pagerItems = [
        ...pagerItems,
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          pathPrefix: pathPrefix,
          increment: currentPage - 1,
        }),
        {
          separator: true,
          index: 'finisher-separator',
        },
      ]
      lastLink = true
    }
  }

  return (
    <>
      {numPages > 1 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={7}
        >
          <Box>
            {!isFirst && prevPageLink && (
              <NavButton
                component={GatsbyLink}
                to={prevPageLink}
                ariaLabel="Goto previous page"
                disableRipple
                startIcon={<CaretLeft />}
              >
                Previous
              </NavButton>
            )}
          </Box>

          <Box
            display="flex"
            component="li"
            alignItems="center"
            justifyContent="center"
            sx={{ listStyle: 'none' }}
          >
            {currentPage > 3 && (
              <Box component="li">
                <PageButton
                  component={GatsbyLink}
                  to={firstPageLink}
                  ariaLabel="Goto page 1"
                  className="pagination-link"
                >
                  1
                </PageButton>
              </Box>
            )}

            {pagerItems.map((item) => {
              return (
                <Box component="li" key={item.index}>
                  {item.separator ? (
                    <span className="pagination-ellipsis">&hellip;</span>
                  ) : (
                    <PageButton
                      component={GatsbyLink}
                      to={item.link}
                      className={`pagination-link ${
                        item.current ? 'is-current' : ''
                      }`}
                      ariaLabel={`Goto page ${item.index}`}
                    >
                      {item.index}
                    </PageButton>
                  )}
                </Box>
              )
            })}

            {lastLink && (
              <Box component="li">
                <PageButton
                  component={GatsbyLink}
                  to={lastPageLink}
                  ariaLabel={`Goto page ${numPages}`}
                  className="pagination-link"
                >
                  {numPages}
                </PageButton>
              </Box>
            )}
          </Box>
          <Box>
            {!isLast && (
              <NavButton
                component={GatsbyLink}
                to={nextPageLink}
                ariaLabel="Goto next page"
                disableRipple
                endIcon={<CaretRight />}
              >
                Next
              </NavButton>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default memo(Pagination)
